import React, {useEffect, useMemo, useState, useRef } from "react";
import { useAuth } from "../../context/UserState";
import { FileRejection, useDropzone } from "react-dropzone";
import UploadImage from "../../images/upload-image.png";
import { Button, Spinner } from "react-bootstrap";
import FileService from "../../services/file.service";
import { useToast } from "../../context/ToastProvider";
import "./upload.css";
import { usePolling } from "../../context/FileLibraryPollingProvider";
import CollectionService from "../../services/collection.service";
import { useCollections } from "../../context/CollectionState";
import Select, {MultiValue } from "react-select";
import CreatableSelect from 'react-select/creatable';

import ApiCollection from "../../types/collection.type";

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 4,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle: React.CSSProperties = {
  borderColor: "#2196f3",
};

const acceptStyle: React.CSSProperties = {
  borderColor: "#00e676",
};

const rejectStyle: React.CSSProperties = {
  borderColor: "#ff1744",
};

type ToastType = "success" | "error";

interface ToastMessage {
  id: number;
  type: ToastType;
  message: string;
  show: boolean;
}
interface OptionType {
  value: string;
  label: string;
  __isNew__?: boolean;
}
const Upload: React.FC<{ onUploadSuccess: () => void; handleClose: () => void }> = ({ onUploadSuccess, handleClose }) => {
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const { addToast, removeToast, clearToasts } = useToast();
  const [successToastId, setSuccessToastId] = useState<number | null>(null);
  const { addFileToPollingList } = usePolling();
  const { organization } = useAuth();
  const uploadLimit = organization?.ingestionConfig?.uploadLimit
  const MAX_FILES_LIMIT = (uploadLimit == null) ? 10 : uploadLimit;
  const {collections, fetchCollections } = useCollections();
  const [customOptions, setCustomOptions] = useState<OptionType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([]);
  const [inputValue, setInputValue] = useState("");
  const selectRef = useRef<any>(null);

  const [collectionsList, setCollectionsList] = useState<string[]>([]);

  useEffect(() => {
    fetchCollections();
  }, []);

  const collectionOptions = useMemo(
    () => [
      ...collections.map((collection) => ({
        value: collection.id,
        label: collection.collection_name,
      })),
      ...customOptions, // Include dynamically created options
    ],
    [collections, customOptions]
  );

  // Handle new option creation and state updates
  const handleNewOption = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue, __isNew__: true };
    const updatedOptions = [...(selectedOptions || []), newOption];
    setSelectedOptions(updatedOptions);
    setCustomOptions((prev) => [...prev, newOption]);
    setInputValue(""); 
    selectRef.current.blur();
  };

  const handleCollectionChange = (options: MultiValue<OptionType>) => {
    setSelectedOptions(options);

    const newCollectionValues = options
      ?.filter((option) => option.__isNew__)
      .map((option) => option.value) || [];

    const existingCollectionValues = options
      ?.filter((option) => !option.__isNew__)
      .map((option) => option.value) || [];

    // Perform actions with `newCollectionValues` and `existingCollectionValues` as needed
  };

  const onDrop = React.useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (myFiles.length + acceptedFiles.length > MAX_FILES_LIMIT) {
        addToast("error", "Upload Limit Exceeded", `You can only upload up to ${MAX_FILES_LIMIT} files.`);
        return;
      }

      if (fileRejections.some(rejection => rejection.errors.some(error => error.code === "file-invalid-type"))) {
        addToast("error", "Invalid File Type", "Must be PDF, PPTX, DOCX, CSV, XLSX, HTML, XML, or MD.");
        return;
      }

      const newFiles: File[] = [];

      acceptedFiles.forEach((file) => {
        if (!myFiles.some((existingFile) => existingFile.name === file.name)) {
          newFiles.push(file);
        } else {
          addToast("error", "Duplicate File", `File "${file.name}" is a duplicate and was not added.`);
        }
      });

      setMyFiles([...myFiles, ...newFiles]);
    },
    [myFiles, addToast]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.ms-powerpoint": [".pptx"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "text/html": [".htm", ".html"],
      "application/xml": [".xml"],
      "text/markdown": [".md"],
    },
    maxFiles: MAX_FILES_LIMIT,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const removeFile = (file: File) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  const getCollectionList = async () => {
    const collectionIds: string[] = [];
    
    if (selectedOptions && selectedOptions.length > 0) {
      let newItems = false;

      for (const option of selectedOptions) {
          if (option.__isNew__ != undefined && option.__isNew__ == true) {
            try {
                newItems = true;
                const newCollection: ApiCollection = {
                  id: '',
                  collection_name: option.value,
                  assigned_file_count: 0
                };

                const response = await CollectionService.createCollection(newCollection);
                console.log(`Collection created:`, response);

                if (response && response.id) {
                  collectionIds.push(response.id.toString());
                }
            } catch (error) {
              console.error(`Error creating collection:`, option, error);
            }
          } else {
            if (option && option.value) {
              collectionIds.push(option.value.toString());
            }
          }
      }

      if (newItems) {
        fetchCollections();
      }
      console.log("All collections processed");
    } 
    
    return collectionIds;
  };

  const files = myFiles.map((file) => (
    <li key={crypto.randomUUID()}>
      {file.name} - {file.size} bytes{" "}
      <i onClick={removeFile(file)} className="bi bi-x-circle" id="remove-btn"></i>
    </li>
  ));

  const handleUploadFiles = async () => {
    if (myFiles.length === 0) {
      addToast("error", "No Files Selected", "Please select files to upload.");
      return;
    }
  
    setLoading(true);
  
    try {
      clearToasts()
      addToast(
        "info",
        "File Upload In Progress",
        "This process is automatic, but can take some time. Please check the file library page for progress.",
        { persistent: true }
      );

      const coll_list = await getCollectionList();

      const uploadPromises = myFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("collections", JSON.stringify(coll_list));

        try {
          const response = await FileService.uploadFile(formData);
          addFileToPollingList(response.file);
          return { success: true, message: `File "${response.file.name}" uploaded successfully.` };
        } catch (error) {
          let errorMessage = "Unknown error";
          if (error instanceof Error) {
            errorMessage = error.message;
          } else if (typeof error === "string") {
            errorMessage = error;
          }
          return { success: false, message: `Failed to upload file "${file.name}": ${errorMessage}` };
        }
      });
  
      const results = await Promise.all(uploadPromises);
  
      const successResults = results.filter((result) => result.success);
      const errorResults = results.filter((result) => !result.success);
  
      if (successResults.length > 0) {
        if (successToastId !== null) {
          removeToast(successToastId);
        }
        const newToastId = addToast(
          "success",
          "Upload Successful",
          `${successResults.length} file${successResults.length > 1 ? 's' : ''} uploaded successfully.`
        );
        setSuccessToastId(newToastId);
      }
  
      if (errorResults.length > 0) {
        errorResults.forEach((result) => {
          addToast("error", "Upload Failed", result.message);
        });
      }
  
      setMyFiles([]);
      onUploadSuccess();
      setTimeout(() => {
        handleClose();
      }, 3000);
    } catch (error) {
      addToast("error", "Upload Error", "An error occurred during the upload process.");
      console.error("Upload process error:", error);
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <div className="upload-wrapper">
      <section className="upload-container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <img src={UploadImage} alt="image upload icon" style={{ backgroundColor: "#fafafa", width: "150px" }} />
          <p style={{ color: "black", fontWeight: "bold" }}>Drag and drop OR click to select (up to {MAX_FILES_LIMIT} files)</p>
          <p style={{ color: "black" }}>Supported Formats: PDF, PPTX, DOCX, CSV, XLSX, HTML, XML, MD</p>
        </div>
      </section>
      <aside>
        <h5>Files</h5>
        <ul>{files}</ul>
        {files.length > 0 && (
          <button onClick={removeAll} className="remove-all-button">
            Remove All <i className="bi bi-trash"></i>
          </button>
        )}
      </aside>
      <div className="collections-select">
        <h5>Assign Collections</h5>
        <CreatableSelect
          ref={selectRef}
          isMulti
          name="collections"
          options={collectionOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          value={selectedOptions}
          onChange={handleCollectionChange}
          inputValue={inputValue}
          onInputChange={(newValue) => setInputValue(newValue)}
          placeholder="Select or create collections..."
          menuPlacement="auto"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              const trimmedValue = inputValue.trim();
              if (trimmedValue) {
                handleNewOption(trimmedValue);
              }
            }
          }}
        />
      </div>
      <Button onClick={handleUploadFiles} disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : "Upload Files"}
      </Button>
    </div>
  );
};

export default Upload;
