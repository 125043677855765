import { useState, useEffect, useRef } from "react"; 
import { useToast } from "../../../context/ToastProvider";
import '../projecttemplate.css'; 
import TemplateService from '../../../services/template.service'; 
import ApiTemplate from '../../../types/template.type'; 
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import React from 'react';
import { Button, Alert } from "react-bootstrap";

const EditTemplate: React.FC = () => { 
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [template, setTemplate] = useState<ApiTemplate>(); 
  const { templateId } = location.state || {};
  const [templateFile, setTemplateFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateDescription, setTemplateDescription] = useState<string>('');
  const { addToast } = useToast();

  useEffect(() => { 
    const fetchTemplate = async () => {
      if (templateId) {
        try {
          const fetchedTemplate = await TemplateService.getTemplate(templateId);
          setTemplate(fetchedTemplate);
          setTemplateName(fetchedTemplate.template_name || '');
          setTemplateDescription(fetchedTemplate.template_description || '');
        } catch (error) {
          setErrorMessage('Failed to load template.');
          addToast("error", "Load Template", `The Template failed to load`);
        }
      }
    };

    fetchTemplate();
  }, [templateId, addToast]);

  const validateFields = () => { 
    const errors = [];

    if (templateName === '') {
        errors.push('Please enter a Template Name.');
    }

    if (!templateId && typeof templateFile === 'undefined') {
        errors.push('Please choose template file to upload.');
    }

    if (errors.length > 0) {
        setErrorMessage(errors.join('\n'));
        return false;
    }

    setErrorMessage(null); 
    return true;
  }; 

  const handleSaveTemplate = async () => {
    if (validateFields()) {
      const formData = new FormData();
  
        if (templateId) {
          formData.append("template_id", templateId)
        }
        if (templateFile) {
          formData.append("file", templateFile, templateFile.name);
        }

        formData.append("template_name", templateName);
        formData.append("template_description", templateDescription);
      
      const response = await TemplateService.uploadFile(formData);
      
      handleClearFields();
      navigate(`/templates`);
      addToast("success", "Template Saved", `The Template "${templateName}" was saved successfully.`);
    }
  };

  const handleTemplateFileChange = (e: any) => {
    setTemplateFile(e.target.files ? e.target.files[0] : undefined);
  };

  const handleCancel = () => {
    navigate(`/templates`);
  };


  const handleClearFields = () => {
    setTemplateName('')
    setTemplateDescription('')
    setTemplateFile(undefined)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; 
    }
  }

  return ( 
    <div className="projecttemplate-container"> 
       
        <div className="projecttemplate-content"> 
          <div className="projecttemplate-header">
            <h3>{templateId ? "Edit" : "Create"} Template</h3>
          </div>  
          <div className="projecttemplate-body">
                    {errorMessage && (
                        <Alert variant="danger">
                            {errorMessage.split('\n').map((msg, index) => (
                                <div key={index}>{msg}</div> // Display each error message in a separate line
                            ))}
                        </Alert>
                      )} 

                  <div className="mb-1"> 
                    
                      <div className="mb-1">
                        <label className="fw-bold">Template Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={templateName || ""}
                          onChange={(e) => setTemplateName(e.target.value)}
                        />
                      </div>
                    
                      <div className="mb-1">
                        <label className="fw-bold">Template Description:</label>
                        <textarea
                          className="form-control"
                          value={templateDescription || ""}
                          onChange={(e) => setTemplateDescription(e.target.value)}
                          rows={4}
                        />
                      </div>
                    
                      <div className="mb-1">
                        <label htmlFor="fileUpload" className="fw-bold">
                          Upload File:
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="fileUpload"
                          ref={fileInputRef}
                          onChange={handleTemplateFileChange}
                        />
                        {templateId ? 
                            <label className="text-danger fst-italic">
                             (Leave blank if you want do not want to change the template file.)
                            </label> 
                              : 
                              ""}
                      </div>
                    
                  </div> 
                  <br /> 
                  <div className="mb-2 d-flex justify-content-between align-items-center"> 
                    <div>
                      <Button variant="outline-primary" title="Clear all Upload Template Fields." className="d-flex align-items-center ps-0 fs-6" onClick={handleClearFields}> 
                        <i className="bi bi-x-circle fs-5 me-1"></i> Clear 
                      </Button>
                    </div>
                    <div className="d-flex">
                      <Button variant="outline-primary" className="d-flex align-items-center ps-0 fs-6" onClick={handleCancel}> 
                        <i className="bi bi-x-circle fs-5 me-1"></i> Cancel
                      </Button> &nbsp;&nbsp;
                      <Button variant="outline-primary" title="Save the Template." className="d-flex align-items-center ps-0 fs-6" onClick={handleSaveTemplate}> 
                        <i className="bi bi-floppy2-fill fs-5 me-2"></i> Template 
                      </Button> 
                    </div>
                  </div> 
          </div>
          
        </div> 
      
    </div> 
  ); 
}; 
 
export default EditTemplate;