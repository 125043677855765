import React, { useEffect, useState, useMemo } from "react";
import UploadModal from "../upload/uploadmodal.component";
import FileService from "../../services/file.service";
import CollectionComponent from "./collection/collection.component";
import CollectionsSelectModal from "./collectionsselectmodal.component";
import ApiFile from "../../types/file.type";
import "./filelibrary.css";
import { Table, Button, Modal, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { useToast } from "../../context/ToastProvider";
import { useCollections } from "../../context/CollectionState";
import ApiCollection from "../../types/collection.type";
import { useAuth } from "../../context/UserState";
import { usePolling } from "../../context/FileLibraryPollingProvider";

interface CollectionsMap {
  [key: string]: ApiCollection[];
}

const FileLibrary = () => {
  const [filteredFilesArray, setFilteredFilesArray] = useState<ApiFile[] | null>(null);
  const [filesArray, setFilesArray] = useState<ApiFile[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedCollectionId, setSelectedCollectionId] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<ApiFile | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [collectionsFileId, setCollectionsFileId] = useState<string>("");
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState<string[]>([]);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [isCollectionsOpen, setIsCollectionsOpen] = useState<boolean>(
    () => JSON.parse(localStorage.getItem("isCollectionsOpen") || "true")
  );
  const { addToast } = useToast();
  const { collections, fetchCollections } = useCollections();
  const { currentUser } = useAuth();
  const { pollingList} = usePolling();

  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");

  const collectionsMap: CollectionsMap = useMemo(() => {
    const map: CollectionsMap = {};
    filesArray.forEach((file) => {
      map[file.id] = collections.filter((collection) => file.collections.includes(collection.id));
    });
    return map;
  }, [filesArray, collections]);

  useEffect(() => {
    fetchFiles();
  }, [pollingList]);

  useEffect(() => {
    fetchFiles();
    fetchCollections();
  }, []);

  useEffect(() => {
    localStorage.setItem("isCollectionsOpen", JSON.stringify(isCollectionsOpen));
  }, [isCollectionsOpen]);

  const fetchFiles = async () => {
    try {
      const files = await FileService.getFiles();
      setFilesArray(files);
    } catch (error) {
      console.log(error);
    }
  };

  const fileSearch = async (search_str: string) => {
    setSearchLoading(true); // Start loading state for search
    try {
      const file_list: string[] = await FileService.getSearchDocuments(search_str);
      const filteredFiles = filesArray.filter((file: { id: string }) =>
        file_list.includes(file.id)
      );
      setFilteredFilesArray(filteredFiles);
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false); // End loading state for search
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue.trim() === "") {
      setFilteredFilesArray(null);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (searchText.trim() === "") {
        setFilteredFilesArray(null);
      } else {
        fileSearch(searchText);
      }
    }
  };

  const sortFiles = (files: ApiFile[], criterion: string, order: "asc" | "desc") => {
    const sortedFiles = [...files].sort((a, b) => {
      if (criterion === "filename") {
        const valueA = a.filename.toLowerCase();
        const valueB = b.filename.toLowerCase();
        return order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else if (criterion === "size") {
        const sizeA = a.file_size;
        const sizeB = b.file_size;
        return order === "asc" ? sizeA - sizeB : sizeB - sizeA;
      } else if (criterion === "status") {
        const statusOrder = ["Uploaded", "Processing", "Active", "Rejected", "Deleting"];
        const indexA = statusOrder.indexOf(a.status);
        const indexB = statusOrder.indexOf(b.status);
        return order === "asc" ? indexA - indexB : indexB - indexA;
      }
      return 0;
    });
    return sortedFiles;
  };

  const handleSort = (criterion: string) => {
    const order = sortBy === criterion && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(criterion);
    setSortOrder(order);
  };

  const displayedFiles = useMemo(() => {
    let files = filteredFilesArray !== null ? filteredFilesArray : filesArray;
  
    if (selectedCollectionId) {
      files = files.filter((file) => file.collections.includes(selectedCollectionId));
    }
  
    if (sortBy && sortOrder) {
      files = sortFiles(files, sortBy, sortOrder);
    }
  
    return files;
  }, [filesArray, filteredFilesArray, selectedCollectionId, sortBy, sortOrder]);

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "Uploaded":
        return { bgColor: "#aec6e8", textColor: "#0d428f" };
      case "Processing":
        return { bgColor: "#ffbe45", textColor: "#8f5d00" };
      case "Active":
        return { bgColor: "#a4ebaf", textColor: "#206b2c" };
      case "Rejected":
        return { bgColor: "#edada1", textColor: "#c72b0e" };
      case "Deleting":
        return { bgColor: "#e34c30", textColor: "#f7f1f0" };
      default:
        return { bgColor: "#d1dcf8", textColor: "#5173c9" };
    }
  };

  const handleDeleteClick = (file: ApiFile) => {
    setFileToDelete(file);
    setShowDeleteModal(true);
  };
  const handleCollectionClick = (collectionId: string) => {
    setSelectedCollectionId(collectionId);
  };

  const handleDeleteConfirm = async () => {
    if (fileToDelete) {
      try {
        setIsDeleting(true);
        await FileService.deleteFile(fileToDelete.id);
        setFilesArray((prevFilesArray) => prevFilesArray.filter((file) => file.id !== fileToDelete.id));
        addToast(
          "success",
          "File Deleted",
          `File "${fileToDelete.filename}" has been deleted successfully.`
        );
      } catch (error) {
        console.log(error);
        addToast(
          "error",
          "Delete Error",
          "An error occurred while deleting the file. Please try again."
        );
      } finally {
        setShowDeleteModal(false);
        setIsDeleting(false);
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setFileToDelete(null);
  };

  const updateFilesArrayWithAddedCollections = (
    updatedFiles: ApiFile[],
    collectionId: string
  ) => {
    setFilesArray((prevFilesArray) =>
      prevFilesArray.map((file) => {
        const updatedFile = updatedFiles.find((updatedFile) => updatedFile.id === file.id);
        if (updatedFile) {
          const isInCollection = updatedFile.collections.includes(collectionId);
          let newCollections;
          if (isInCollection) {
            newCollections = Array.from(new Set([...file.collections, collectionId]));
          } else {
            newCollections = file.collections.filter((c) => c !== collectionId);
          }
          return { ...file, collections: newCollections };
        }
        return file;
      })
    );
  };

  const formatFileSize = (size: number) => {
    if (size >= 1048576) {
      return (size / 1048576).toFixed(2) + " MB";
    } else if (size >= 1024) {
      return (size / 1024).toFixed(2) + " KB";
    } else {
      return size + " bytes";
    }
  };

  const handleCollectionFieldClick = (fileId: string, fileCollections: ApiCollection[]) => {
    setCollectionsFileId(fileId);
    setSelectedCollections(fileCollections.map((collection) => collection.id));
    setShowCollectionsModal(true);
  };

  const handleSaveCollections = async (selected: string[]) => {
    try {
      await FileService.updateFileCollection(collectionsFileId, selected);
      setFilesArray((prevFilesArray) =>
        prevFilesArray.map((file) =>
          file.id === collectionsFileId ? { ...file, collections: selected } : file
        )
      );
      addToast(
        "success",
        "Collections Updated",
        "The collections for the selected file have been updated successfully."
      );
    } catch (error) {
      console.log(error);
      addToast(
        "error",
        "Update Error",
        "An error occurred while updating the collections. Please try again."
      );
    } finally {
      setShowCollectionsModal(false);
      setCollectionsFileId("");
      setSelectedCollections([]);
    }
  };

  const handleCloseCollections = () => {
    setShowCollectionsModal(false);
    setCollectionsFileId("");
    setSelectedCollections([]);
  };

  const handleClearFilter = () => {
    setSelectedCollectionId(null);
  };

  const handleDownloadClick = async (file: ApiFile) => {
    const filename = file.filename;
    try {
      const url = await FileService.getFileDownloadURL(file.id);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      addToast(
        "success",
        "File Downloaded",
        filename + " has been downloaded to your computer successfully."
      );
    } catch (error) {
      console.log(error);
      addToast(
        "error",
        "Download Error",
        "An error occurred while attempting to download " + filename + ". Please try again."
      );
    }
  };

  const isAdmin = currentUser?.permissions?.includes("admin");
  const isOrgAdmin = currentUser?.permissions?.includes("org-admin");
  const selectedCollection = collections.find((collection) => collection.id === selectedCollectionId);

  return (
    <div className="repo-page-container">
        <div className={`collections-container ${isCollectionsOpen ? 'col-open' : 'col-closed'}`}>
          <CollectionComponent
            filesArray={filesArray}
            onCollectionClick={handleCollectionClick}
            updateFilesArray={updateFilesArrayWithAddedCollections}
            isCollectionsOpen={isCollectionsOpen}
            setIsCollectionsOpen={setIsCollectionsOpen}
            />
        </div>

      <div className="cont-con">
      <div className="library-wrapper">
      <div className="file-lib-container">
        <h3>File Library</h3>
        <div className="upload-modal">
          {(isAdmin || isOrgAdmin) && (
            <Button variant="primary" onClick={() => setShowUploadModal(true)}>
              Upload Files
            </Button>
          )}
        </div>
      </div>
      <br />
      <div>
        {selectedCollection && (
          <div>
            <br />
            <strong>Filtering by Collection:</strong>
            <Button
              variant="outline-primary"
              onClick={handleClearFilter}
              className="clear-collection-filter-btn ml-2"
            >
              {selectedCollection.collection_name} <i className="bi bi-x"></i>
            </Button>
          </div>
        )}
            <div className="search-and-filter">
              <div className="search-bar">
                <i className="bi bi-search"></i>
                <input 
                  className="search-input"
                  value={searchText}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Search files..."
                  aria-label="Search files" 
                />
                <i className="bi bi-x" onClick={() => {setSearchText(''); setFilteredFilesArray(null);}}></i>
              </div>   
              
              <div className="file-count">
                Displaying {displayedFiles.length} {displayedFiles.length === 1 ? "file" : "files"}
              </div>
     
            </div>
            {
              searchLoading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                  </Spinner>
                </div>
              ) : (
                <>
                  {displayedFiles.length > 0 ? (
                    <Table hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("filename")} style={{ cursor: "pointer" }} className="sortable">
                            File Name{" "}
                            {sortBy === "filename" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                          </th>
                          <th onClick={() => handleSort("size")} style={{ cursor: "pointer" }} className="sortable">
                            Size {sortBy === "size" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                          </th>
                          <th>Assigned Collections</th>
                          <th onClick={() => handleSort("status")} style={{ cursor: "pointer" }} className="sortable">
                            Status {sortBy === "status" ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                          </th>
                          {(isAdmin || isOrgAdmin) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {displayedFiles.map((file, index) => (
                          <tr key={index}>
                            <td>{file.filename}</td>

                            <td>{formatFileSize(file.file_size)}</td>
                              <td>
                                {collectionsMap[file.id] ? (
                                  collectionsMap[file.id].length > 0 ? (
                                    <>
                                      {collectionsMap[file.id]
                                        .slice(0, 3)
                                        .map((collection, idx) => (
                                          <div key={collection.id}>
                                            <a
                                              href="#"
                                              onClick={() =>
                                                handleCollectionFieldClick(
                                                  file.id,
                                                  collectionsMap[file.id]
                                                )
                                              }
                                            >
                                              {collection.collection_name}
                                            </a>
                                            {idx === Math.min(collectionsMap[file.id].length, 3) - 1 && (
                                              <>
                                                {collectionsMap[file.id].length > 3 && <span> ...</span>}
                                                <a
                                                  href="#"
                                                  onClick={() =>
                                                    handleCollectionFieldClick(
                                                      file.id,
                                                      collectionsMap[file.id]
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-plus-circle add_collection_icon"></i>
                                                </a>
                                              </>
                                            )}
                                          </div>
                                        ))}
                                    </>
                                  ) : (
                                    <div>
                                      <a
                                        href="#"
                                        onClick={() =>
                                          handleCollectionFieldClick(file.id, collectionsMap[file.id])
                                        }
                                      >
                                        <i className="bi bi-plus-circle add_collection_icon"></i>
                                      </a>
                                    </div>
                                  )
                                ) : (
                                  "Loading..."
                                )}
                              </td>

                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  file.status === "Processing" || file.status === "Deleting" ? (
                                    <Tooltip>
                                      This process is automatic, but can take some time. Please check back
                                      later. Thanks for your patience.
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )
                                }
                              >
                                <span
                                  style={{
                                    backgroundColor: `${getStatusBadgeClass(file.status).bgColor}`,
                                    color: `${getStatusBadgeClass(file.status).textColor}`,
                                    display: "inline-block",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    padding: "4px 6px",
                                    borderRadius: "8px",
                                    width: "90px",
                                  }}
                                >
                                  {file.status}
                                </span>
                              </OverlayTrigger>
                            </td>
                            {(isAdmin || isOrgAdmin) && (
                              <td>
                                <div className="icon-wrapper">
                                  {file.status === "Active" && (
                                    <>
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Download {file.filename}</Tooltip>}
                                      >
                                        <i
                                          className="bi bi-download"
                                          id="download-icon"
                                          onClick={() => handleDownloadClick(file)}
                                        ></i>
                                      </OverlayTrigger>

                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Delete {file.filename}</Tooltip>}
                                      >
                                        <i
                                          className="bi bi-trash"
                                          id="trash-icon"
                                          onClick={() => handleDeleteClick(file)}
                                        ></i>
                                      </OverlayTrigger>
                                    </>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center text-muted">
                    {searchText.trim() === "" ? (
                      "To get started, upload a file"
                    ) : (
                      "No files match your search"
                    )}
                  </div>
                  )}
                </>
              )
            }
      </div>

      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please confirm you want to delete this file?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            style={{ width: "80px" }}
          >
            {isDeleting ? <Spinner animation="border" size="sm" /> : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
      <CollectionsSelectModal
        show={showCollectionsModal}
        onHide={handleCloseCollections}
        collections={collections}
        selectedCollections={selectedCollections}
        onSave={handleSaveCollections}
      />
      <UploadModal
        show={showUploadModal}
        handleClose={() => setShowUploadModal(false)}
        onUploadSuccess={fetchFiles}
      />
    </div>
    </div>
    </div>
  );
};

export default FileLibrary;
