import React, { useState, useEffect, ReactElement } from "react";
import { Accordion, Card, Form, Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { ApiOrganization, SsoIntegration, IngestionConfig } from "../../types/organization.type";
import OrganizationService from "../../services/organization.service";
import OrganizationModal from "./organizationmodal.component";
import Select from "react-select";
import { Controlled as CodeMirror } from "react-codemirror2";
import styles from "./admin.module.css"
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript";
import { Table, OverlayTrigger, Tooltip, Spinner, Alert, InputGroup, FormControl, Row, Col } from "react-bootstrap";

interface EditableSsoIntegration extends SsoIntegration {
  tempId?: number; // Temporary ID for React key purposes
}

const Organization: React.FC = () => {
  const [organizations, setOrganizations] = useState<ApiOrganization[]>([]);
  const [organizationToEdit, setOrganizationToEdit] = useState<ApiOrganization | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    const orgs = await OrganizationService.getOrganizations();
    setOrganizations(orgs);
  };

  const handleCreateClick = () => {
    setOrganizationToEdit(null);
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setOrganizationToEdit(null);
    setShowEditModal(false);
  };

  const handleEditClick = (organizationToEdit: ApiOrganization) => {
    setOrganizationToEdit(organizationToEdit);
    setShowEditModal(true);
  };

  const handleDuplicateClick = (organizationToEdit: ApiOrganization) => {
    const newOrganization = JSON.parse(JSON.stringify(organizationToEdit));
    newOrganization.name = newOrganization.name + ' [Duplicate]';
    newOrganization.id = '';
    setOrganizationToEdit(newOrganization);
    setShowEditModal(true);
  };

  return (
    <div className="container pt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h3>Organizations</h3>
        {(
          <Button variant="outline-primary"
          className="d-flex align-items-center ps-0 fs-6"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{e.stopPropagation(); handleCreateClick();}} >
          <i className="bi bi-plus fs-5 me-0"></i> Organization
        </Button>
        )}
      </div>
      <br></br>
      <div className="create-modal">
        <OrganizationModal
          onSaveSuccess={(organization) => {
            fetchOrganizations();
          }}
          organizationToEdit={organizationToEdit}
          onClose={handleEditModalClose}
          onShow={showEditModal}
        />
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>&nbsp;</th>
            <th className="text-end">Actions&nbsp;&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map((org) => (
          <tr key={org.id}>
            <td className="flex-grow-1">
              {org.name}
            </td>
            <td>&nbsp;</td>
            <td className="text-end">
              <i className="bi bi-pencil fs-6" onClick={() => handleEditClick(org)}></i>&nbsp;
              <i className="bi bi-copy fs-6" onClick={() => handleDuplicateClick(org)}></i>
            </td>
          </tr>
          ))}
        </tbody>
      </Table>
      
    </div>
  );
};

export default Organization;
