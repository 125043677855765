import React, { useEffect, useState } from "react";
import CollectionModal from "./collectionmodal.component";
import { useCollections } from "../../../context/CollectionState";
import ApiCollection from "../../../types/collection.type";
import { Card, Modal, Spinner, Button, Dropdown } from "react-bootstrap";
import { useToast } from "../../../context/ToastProvider";
import CollectionService from "../../../services/collection.service";
import "./collection.css"; // Import the CSS file for styles
import ApiFile from "../../../types/file.type";
import AddFilesModal from "./AddFilesModal.component";
import { useNavigate } from "react-router-dom";

interface CollectionsProps {
  filesArray: ApiFile[];
  onCollectionClick: (collectionId: string) => void;
  updateFilesArray: (updatedFiles: ApiFile[], collectionId: string) => void;
  isCollectionsOpen: boolean;
  setIsCollectionsOpen: (state: boolean) => void;
}

const Collections: React.FC<CollectionsProps> = ({ filesArray, onCollectionClick, updateFilesArray, isCollectionsOpen, setIsCollectionsOpen }) => {
  const {
    collections,
    handleAddCollection,
    handleRemoveCollection,
    handleUpdateCollection,
    setSelectedCollection,
  } = useCollections();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState<ApiCollection | null>(null);
  const [collectionToRename, setCollectionToRename] = useState<ApiCollection | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { addToast } = useToast();
  const [showAddFilesModal, setShowAddFilesModal] = useState(false);
  const [collectionToAddFiles, setCollectionToAddFiles] = useState<ApiCollection | null>(null);
  const [fileCounts, setFileCounts] = useState<{ [key: string]: number }>({});
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const counts = collections.reduce(
      (acc, collection) => {
        acc[collection.id] = 0;
        return acc;
      },
      {} as { [key: string]: number }
    );

    filesArray.forEach((file: ApiFile) => {
      file.collections.forEach((collectionId) => {
        if (counts[collectionId] !== undefined) {
          counts[collectionId]++;
        }
      });
    });

    setFileCounts(counts);
  }, [filesArray, collections]);

  const handleDeleteConfirm = async () => {
    if (collectionToDelete) {
      try {
        setIsDeleting(true);
        await CollectionService.deleteCollection(collectionToDelete);
        handleRemoveCollection(collectionToDelete.id);
        addToast(
          "success",
          "Collection Deleted",
          `The collection "${collectionToDelete.collection_name}" was deleted successfully.`
        );
      } catch (error) {
        console.log(error);
        addToast("error", "Delete Error", "An error occurred while deleting the collection. Please try again.");
      } finally {
        setShowDeleteModal(false);
        setIsDeleting(false);
      }
    }
  };

  const handleEditModalClose = () => {
    setCollectionToRename(null);
    setShowRenameModal(false);
  };


  const handleCardClick = (collectionId: string) => {
    onCollectionClick(collectionId);
  };

  const handleDeleteClick = (collection: ApiCollection) => {
    setCollectionToDelete(collection);
    setShowDeleteModal(true);
  };

  const handleRenameClick = (collectionToRename: ApiCollection) => {
    setCollectionToRename(collectionToRename);
    setShowRenameModal(true);
  };

  const handleAddFilesClick = (collection: ApiCollection) => {
    setCollectionToAddFiles(collection);
    const filesWithCollection = filesArray.filter((file) => file.collections.includes(collection.id));
    setSelectedFiles(filesWithCollection.map((file) => file.id));
    setShowAddFilesModal(true);
  };

  const handleFilesAdded = (updatedFiles: ApiFile[], collectionId: string) => {
    const countChange = updatedFiles.reduce((count, file) => {
      const wasInCollection = file.collections.includes(collectionId);
      const isInCollectionNow = file.collections.includes(collectionId);

      if (!wasInCollection && isInCollectionNow) return count + 1;
      if (wasInCollection && !isInCollectionNow) return count - 1;
      return count;
    }, 0);

    setFileCounts((prevCounts) => ({
      ...prevCounts,
      [collectionId]: prevCounts[collectionId] + countChange,
    }));
    updateFilesArray(updatedFiles, collectionId);
    addToast("success", "Files Updated", "The files have been successfully updated in the collection.");
  };

  const handleChatWithCollectionClick = (collection: ApiCollection) => {
    setSelectedCollection(collection.id);
    navigate("/chat");
  };

  const handleCreateClick = () => {
    setCollectionToRename(null);
    setShowRenameModal(true);
  };


  return (
    <>
      <div
        className={`collection-toggle ${isCollectionsOpen ? 'col-open' : 'col-closed'}`}
        onClick={() => setIsCollectionsOpen(!isCollectionsOpen)}
      >
        <i id="collection-toggle-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 22 22">
            <path d="M20.2812 0.6875H7.21875C6.94525 0.6875 6.68294 0.796149 6.48955 0.989546C6.29615 1.18294 6.1875 1.44525 6.1875 1.71875V14.7812C6.1875 15.0548 6.29615 15.3171 6.48955 15.5105C6.68294 15.7038 6.94525 15.8125 7.21875 15.8125H20.2812C20.5548 15.8125 20.8171 15.7038 21.0105 15.5105C21.2038 15.3171 21.3125 15.0548 21.3125 14.7812V1.71875C21.3125 1.44525 21.2038 1.18294 21.0105 0.989546C20.8171 0.796149 20.5548 0.6875 20.2812 0.6875ZM19.9375 14.4375H7.5625V2.0625H19.9375V14.4375Z" fill="#3869ad" />
            <path d="M4.8125 17.1875V3.4375H3.4375V17.5312C3.4375 17.8048 3.54615 18.0671 3.73955 18.2605C3.93294 18.4538 4.19525 18.5625 4.46875 18.5625H18.5625V17.1875H4.8125Z" fill="#3869ad" />
            <path d="M2.0625 19.9375V6.1875H0.6875V20.2812C0.6875 20.5548 0.796149 20.8171 0.989546 21.0105C1.18294 21.2038 1.44525 21.3125 1.71875 21.3125H15.8125V19.9375H2.0625Z" fill="#3869ad" />
          </svg>
        </i>
        <span className="collection-text">Collections</span>
        {isCollectionsOpen && <i className="bi bi-x" id="collection-toggle-x" style={{ fontSize: "1.5rem" }} onClick={() => setIsCollectionsOpen(!isCollectionsOpen)}></i>}
      </div>

      {isCollectionsOpen && (
        <div className="cards-container">
          <Card
            className="collection-card create-collection-card btn-primary"
            onClick={handleCreateClick}
          >
            <Card.Body>
              <div className="title-container">
                <Card.Title className="collection-card-title">
                  + Create Collection
                </Card.Title>
              </div>
            </Card.Body>
          </Card>
          {collections.map((collection) => (
            <Card key={collection.id} className="collection-card mb-2">
              <Card.Body>
                <div
                  className="title-container"
                  onClick={() => handleCardClick(collection.id)}
                >
                  <Card.Title className="collection-card-title" title={collection.collection_name}>
                    {collection.collection_name.length > 20 ? collection.collection_name.slice(0, 20) + "..." : collection.collection_name}
                  </Card.Title>
                  <span className="collection-card-file-count">
                    ({fileCounts[collection.id] || 0} files)
                  </span>
                </div>
                <Dropdown className="three-dots-menu" align="end">
                  <Dropdown.Toggle as="i" className="bi bi-three-dots-vertical" />
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleRenameClick(collection)}>Rename</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleAddFilesClick(collection)}>
                      Manage Files
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleChatWithCollectionClick(collection)}>
                      Chat with Collection
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteClick(collection)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Card.Body>
            </Card>
          ))}
        </div>
      )}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm you want to delete this collection: {collectionToDelete?.collection_name}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            style={{ width: "80px" }}
          >
            {isDeleting ? <Spinner animation="border" size="sm" /> : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>

      <CollectionModal
        onSaveSuccess={(collection) => {
          if (collectionToRename) {
            handleUpdateCollection(collection);
          } else {
            handleAddCollection(collection);
          }
        }}
        collectionToEdit={collectionToRename}
        onClose={handleEditModalClose}
        onShow={showRenameModal}
      />
      <AddFilesModal
        show={showAddFilesModal}
        onHide={() => setShowAddFilesModal(false)}
        collection={collectionToAddFiles}
        selectedFiles={selectedFiles}
        onFilesAdded={(files) => handleFilesAdded(files, collectionToAddFiles?.id || "")}
        availableFiles={filesArray}
      />
    </>
  );
};

export default Collections;
