import React, { useState } from 'react';
import { Form, Card, Button, InputGroup, FormControl } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importing Bootstrap Icons
import FileService from "../../services/file.service";

interface AnnotationProps {
    annotation: {
        type: string;
        tool_name: string;
        params?: string;
        source?: string;
        documents?: any; // Accept any type
    };
}

interface Document {
    file_id: string;
    document_summary: string;
    source: string;
    [key: string]: any; // For any additional fields
}

const Annotation: React.FC<AnnotationProps> = ({ annotation }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    let paramsObj: { [key: string]: any } = {};
    if (annotation.params) {
        try {
            paramsObj = JSON.parse(annotation.params.replace(/'/g, '"'));
        } catch (e) {
            console.error('Error parsing params JSON:', e);
        }
    }

    let documentsArray: Document[] = [];
    if (annotation.documents) {
        if (typeof annotation.documents === 'string') {
            try {
                documentsArray = JSON.parse(annotation.documents);
            } catch (e) {
                console.error('Error parsing documents JSON:', e);
            }
        } else if (Array.isArray(annotation.documents)) {
            documentsArray = annotation.documents;
        } else if (typeof annotation.documents === 'object') {
            documentsArray = [annotation.documents];
        }
    }

    const handleDownload = async (fileId: string) => {
        try {
            const url = await FileService.getFileDownloadURL(fileId);
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error getting file download URL:', error);
        }
    };

    const renderParams = (params: any, indentLevel: number = 0) => {
        return Object.entries(params).map(([key, value]) => (
            <div key={key} style={{ marginLeft: `${indentLevel * 1}rem`, marginBottom: '0.5rem' }}>
                <Form.Label
                    style={{
                        fontWeight: '500',
                        fontSize: '0.700rem',
                    }}
                >
                    {key}
                </Form.Label>
                {typeof value === 'object' && value !== null ? (
                    renderParams(value, indentLevel + 1)
                ) : (
                    <Form.Control
                        plaintext
                        readOnly
                        defaultValue={String(value)}
                        style={{
                            borderBottom: '1px solid #ddd',
                            fontSize: '0.700rem',
                        }}
                    />
                )}
            </div>
        ));
    };
    return (
        <Card className="mb-3 shadow-sm" style={{ width: '50%'  }}>
            <Card.Body style={{ padding: '0.5rem' }}>
                <InputGroup className="mb-2" style={{ height: '40px' }}>
                    <InputGroup.Text
                        style={{
                            fontWeight: 'bold',
                            backgroundColor: '#e9ecef',
                            borderRight: 'none',
                            height: '100%',
                            fontSize: '0.700rem' // Reduced font size
                        }}
                    >
                    <span style={{ marginRight: '5px', fontWeight: 'bold' }}>{annotation.type}</span>
                    </InputGroup.Text>
                    <FormControl
                        readOnly
                        defaultValue={annotation.tool_name}
                        style={{ borderRight: 'none', height: '100%', marginTop: '0px', fontSize: '0.700rem' }} // Reduced font size
                    />
                    <InputGroup.Text
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0 10px',
                        }}
                    >
                        <Button
                            variant="link"
                            onClick={handleToggle}
                            style={{
                                padding: '0',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {expanded ? (
                                <i className="bi bi-chevron-up" style={{ fontSize: '1rem', color: '#007bff' }}></i>
                            ) : (
                                <i className="bi bi-chevron-down" style={{ fontSize: '1rem', color: '#007bff' }}></i>
                            )}
                        </Button>
                    </InputGroup.Text>
                </InputGroup>

                {expanded && (
                    <div
                        style={{
                            marginTop: '0.5rem',
                            padding: '0.5rem',
                            border: '1px solid #f0f0f0',
                            borderRadius: '0.25rem',
                            backgroundColor: '#f8f9fa',
                        }}
                    >
                        {documentsArray.length > 0 ? (
                            <>
                                {documentsArray.map((doc: Document, index: number) => (
                                    <div key={index} style={{ marginBottom: '1rem', fontSize: '0.700rem' }}>
                                        <Form.Group
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '0.5rem',
                                            }}
                                        >
                                            <Form.Label style={{ marginRight: '1rem', fontWeight: '500' }}>
                                                Source:
                                            </Form.Label>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                    doc.source ? doc.source.split('/').pop() : ''
                                                }
                                                style={{ flex: 1, borderBottom: '1px solid #ddd', fontSize: '0.700rem' }}
                                                title={
                                                    doc.source ? doc.source.split('/').pop() : ''
                                                }
                                            /> <i className="bi bi-download fs-6" onClick={() => handleDownload(doc.file_id)}></i>
                                        </Form.Group>
                                        <Form.Group style={{ marginBottom: '0.5rem' }}>
                                            <Form.Label style={{ fontWeight: '500' }}>
                                                Document Summary:
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                plaintext
                                                readOnly
                                                defaultValue={doc.document_summary || ''}
                                                style={{ borderBottom: '1px solid #ddd', fontSize: '0.700rem' }}
                                            />
                                        </Form.Group>
                                        <hr />
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <Form.Label
                                    style={{ fontWeight: 'bold', marginBottom: '0.5rem', fontSize: '0.700rem' }}
                                >
                                    Parameters
                                </Form.Label>
                                {renderParams(paramsObj)}
                            </>
                        )}
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default Annotation;
