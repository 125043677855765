import { useEffect, useState } from "react";
import { useAuth } from "../../context/UserState";
import { ApiOrganization } from '../../types/organization.type';
import OrganizationService from "../../services/organization.service";
import ResetPasswordModal from "../ResetPasswordModal.component";
import IUser from "../../types/user.type";
import './settings.css'
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { useToast } from '../../context/ToastProvider'; // Import the Toast context
import ChatService from "../../services/chat.service";

const Settings: React.FC = () => {
  const { currentUser } = useAuth() as { currentUser: IUser | null };
  const { organization } = useAuth() as { organization: ApiOrganization | null };
  const isAdmin = currentUser?.permissions?.includes("admin");
  const isSystemPromptEdit = currentUser?.permissions?.includes("systemprompt.edit");
  const [ systemPrompt, setSystemPrompt ] = useState<string>('')
  const [ newSystemPrompt, setNewSystemPrompt ] = useState<string>('')
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false); // State for delete modal
  const [editMode, setEditMode] = useState<boolean>(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const { addToast } = useToast(); // For displaying toast messages

  const fetchOrganization = async (orgId: string) => {
      
      const prompt = await OrganizationService.getSystemPrompt(orgId);
      setSystemPrompt(prompt);
    };

  useEffect(() => {
    const currentOrgId = organization?.id || ''
    if (currentOrgId != '' && systemPrompt == '') {
      fetchOrganization(currentOrgId);
    }

    const isSSOUser = currentUser?.type?.includes("sso");

    if (isSSOUser) {
      navigate("/chat"); // Redirect to the chat page if the user is an SSO user
    }
  }, [currentUser, navigate, fetchOrganization]);

  const handleResetPasswordClick = () => {
    setShowPasswordModal(true);
  };

  const handleEditPromptClick = () => {
    setEditMode(true);
  }

  const handleSavePromptClick = async () => {
    const orgId = organization?.id || ''
    const returnData = await OrganizationService.setSystemPrompt(orgId, systemPrompt);
    setEditMode(false);
  }

  const handleCancelClick = () => {
    setEditMode(false);
  }

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteChatHistoryClick = () => {
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false); // Close the delete confirmation modal
  };

  const handleConfirmDelete = async () => {
    try {
       
      await ChatService.deleteAllChatSessions();
      // Assuming deletion is successful, show a toast notification
      addToast('success', 'Chat History Deleted', 'All your chat history has been deleted successfully.');
    } catch (error) {
      console.error('Error deleting chat history:', error);
      addToast('error', 'Deletion Failed', 'An error occurred while deleting your chat history. Please try again.');
    } finally {
      setShowDeleteModal(false); // Close the modal after action
    }
  };

  return (
    <div className="settings-container">
      {currentUser && (
        <div className="settings-content">
          <div className="settings-header">
            <h3>Settings</h3>
          </div>
          <div className="settings-body">
            
            <div className="card mb-3">
              <div className="card-header">
                  <h4>Profile</h4>
              </div>
              <div className="card-body">
                <div className="mb-2 d-flex align-items-center justify-content-between">
                  
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <i className="bi bi-person-circle settings-icon"></i>
                    </div>
                    
                    <div className="settings-text">
                      <h5 className="settings-name mb-1">{currentUser.fname} {currentUser.lname}</h5>
                      <p className="settings-email mb-0">{currentUser.email}</p>
                    </div>
                  </div>

                  <div className="text-end">
                    <button className="btn btn-outline-primary me-2" onClick={handleResetPasswordClick}>
                      Reset Password
                    </button>
                  </div>      
                </div>

              </div>
            </div>

            {(isAdmin || isSystemPromptEdit) && (
                <div className="card mb-3">
                  <div className="card-header">
                      <h4>AI Configuration</h4>
                  </div>
                  <div className="card-body">

                    <div className="mb-2">
                        <div>
                          <h6>System Prompt:</h6>
                        </div>

                        {(editMode) && (
                          <>
                            <textarea
                              className="form-control"
                              value={systemPrompt || ""}
                              rows={10}
                              onChange={(e) => setSystemPrompt(e.target.value)}
                            />
                             <label className="text-danger fst-italic">
                              * This System Prompt is a global setting for your entire organization and will affect all users within your organization.
                            </label>
                            <br></br>
                            <div className="mb-2 d-flex justify-content-end"> 
                              <button className="btn btn-outline-primary" onClick={handleCancelClick}>
                                Cancel
                              </button> &nbsp;
                              <button className="btn btn-outline-primary" onClick={handleSavePromptClick}>
                                Save Prompt
                              </button>
                            </div>
                          </>
                      )}

                      {(!editMode) && (
                        <>
                          <textarea
                            className="form-control border-0 shadow-none"
                            value={systemPrompt || ""}
                            rows={10}
                          />
                          <br></br>
                          <div className="mb-2 d-flex justify-content-end"> 
                            <button className="btn btn-outline-primary" onClick={handleEditPromptClick}>
                              Edit Prompt
                            </button>
                          </div>
                          </>                           
                          
                        )}
                      </div>

                  </div>
                </div>
            )}

            <div className="card mb-3">
              <div className="card-header">
                  <h4>Privacy</h4>
              </div>
              <div className="card-body">
                
                <div className="mb-2">
                  <button className="btn btn-outline-danger" onClick={handleDeleteChatHistoryClick}>
                    Delete All Chat History
                  </button>          
                </div>

              </div>
            </div>

        </div>
      </div>
      )}
      <ResetPasswordModal
        show={showPasswordModal}
        handleClose={handleClosePasswordModal}
        currentUser={currentUser}
      />

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all your chat history? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Settings;
