import axios from "axios";
import authHeader from "./auth-header";
import { API_BASE_URL } from "../constants";
import ApiTemplate from "../types/template.type";
import ApiTemplateInstructionsObject from "../types/templateinstructionsobject.type";

class TemplateService {
  private baseUrl = API_BASE_URL + "template";

  async uploadFile(fileData: FormData): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}`, fileData, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // or handle it appropriately
    }
  }

  async getTemplate(template_id: string): Promise<ApiTemplate> { //use an id from org instead of whole org obj
    const response = await axios.get(`${this.baseUrl}/${template_id}`,{ headers: authHeader() });
    return response.data;
  }

  async getTemplateInstructions(template_id: string): Promise<ApiTemplateInstructionsObject> { //use an id from org instead of whole org obj
    const response = await axios.get(`${this.baseUrl}/${template_id}/instructions`,{ headers: authHeader() });
    
    const templateInstructionsObj: ApiTemplateInstructionsObject = {
      template_id: template_id,
      template_name: response.data.template_name,
      project_id: '',
      project_name: response.data.project_name,
      collection_id: '',
      instructions: []
    };

    templateInstructionsObj.instructions = response.data

    return templateInstructionsObj;
  }

  async getTemplates(): Promise<ApiTemplate[]> {
    const response = await axios.get(this.baseUrl, { headers: authHeader() });
    return response.data;
  }

  async processTemplate(template_id: string, project_id: string, collection_id: string): Promise<string> {
    try {
      const response = await axios.get(`${this.baseUrl}`, 
        {
          params: {
              project_id: project_id,
              template_id: template_id,
              collection_id: collection_id,
          },
          headers: authHeader(),
        }
      );
      
      return response.data;
    } catch (error) {
      console.error("Error processing template:", error);
      throw error; 
    }
  }

  async processTemplateInstructions(templateInstructionsObj: ApiTemplateInstructionsObject): Promise<string> {
    const template_id = templateInstructionsObj.template_id
    const response = await axios.post(this.baseUrl + "/processinstructions", templateInstructionsObj, { headers: authHeader() });
    return response.data;
  }

  async deleteTemplate(template: ApiTemplate): Promise<ApiTemplate[]> {
    try {
      const response = await axios.delete(`${this.baseUrl}/${template.id}`, { headers: authHeader() });
      return response.data;
    } catch (error) {
      console.error("Error fetching template:", error);
      throw error; 
    }
  }

  async getTemplateDownloadURL(template_id: string): Promise<string> {
    try {
      const response = await axios.get(`${this.baseUrl}/${template_id}/download-url`, {
        headers: authHeader(),
      });
      return response.data.presigned_url;
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      throw error; 
    }
  }
}
export default new TemplateService();
