import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import IUser from "../../types/user.type";
import UserService from "../../services/user.service";
import { ApiOrganization } from "../../types/organization.type";
import OrganizationService from "../../services/organization.service";
import UserModal from "./usermodal.component";
import { Table } from "react-bootstrap";
import "./admin.module.css";

const User: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [userToEdit, setUserToEdit] = useState<IUser | null>(null);
  const [organizations, setOrganizations] = useState<ApiOrganization[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [sortBy, setSortBy] = useState<string>("name");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("asc");

  useEffect(() => {
    setSortBy("");
    setSortOrder("");
    fetchUsers();
    fetchOrganizations();
  }, []);

  const fetchUsers = async () => {
    const users = await UserService.getUsers();
    setUsers(users);
  };

  const sortUsers = (users: IUser[], criterion: string, order: "asc" | "desc") => {
    const sortedUsers = [...users].sort((a, b) => {
      let valueA = "";
      let valueB = "";

      if (criterion === "name") {
        valueA = (a.fname?.toLowerCase() + ' ' + a.lname?.toLowerCase()) || "";
        valueB = (b.fname?.toLowerCase() + ' ' + b.lname?.toLowerCase()) || "";
      } else if (criterion === "email") {
        valueA = a.email?.toLowerCase() || "";
        valueB = b.email?.toLowerCase() || "";
      } else if (criterion === "organization") {
        const orgA_name = getOrganizationName(a.org_ids?.[0],  organizations);
        const orgB_name = getOrganizationName(b.org_ids?.[0], organizations);
        valueA = orgA_name.toLowerCase() || "";
        valueB = orgB_name.toLowerCase() || "";
      } 
      
      if (criterion === "lastPasswordChange") {
        let numA = new Date(a.last_login || 0).getTime();
        let numB = new Date(b.last_login || 0).getTime();
        return order === "asc" ? numA - numB : numB - numA;
      } else {
        return order === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    });
    setUsers(sortedUsers);
  };

  const handleSort = (criterion: string) => {
    const order = sortBy === criterion && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(criterion);
    setSortOrder(order);
    sortUsers(users, criterion, order);
  };

  const fetchOrganizations = async () => {
    const orgs = await OrganizationService.getOrganizations();
    setOrganizations(orgs);
  };

  const startEditing = (user: IUser) => {
    setUserToEdit(user);
    setShowEditModal(true);
  };

  const handleDuplicate = (user: IUser) => {
    const newUser = JSON.parse(JSON.stringify(user));
    newUser.id = '';
    newUser.email = '[REPLACE]@' + newUser.email.split('@')[1];
    newUser.lname = ''
    newUser.fname = '';
    newUser.force_password_change = true;

    setUserToEdit(newUser);
    setShowEditModal(true);
  };

  const formatDate = (date: Date | undefined) => {
    if (!date) return "N/A";
    return new Date(date).toLocaleString();
  };

  const getOrganizationName = (orgId: string | undefined, organizations: ApiOrganization[]): string => {
    if (!orgId) return "N/A";
    const org = organizations.find((org) => org.id === orgId);
    return org && org.name ? org.name : "N/A";
  };

  const handleEditModalClose = () => {
    setUserToEdit(null);
    setShowEditModal(false);
    fetchOrganizations();
  };

  const handleCreateClick = () => {
    setUserToEdit(null);
    setShowEditModal(true);
  };

  return (
    <div className="container pt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h3>Users</h3>
        {(
          <Button variant="outline-primary"
            className="d-flex align-items-center ps-0 fs-6"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{e.stopPropagation(); handleCreateClick();}} >
            <i className="bi bi-plus fs-5 me-0"></i> User
          </Button>
        )}
      </div>
      <br></br>
      <div className="create-modal">
        <UserModal
          onSaveSuccess={(user) => {
            fetchUsers();
          }}
          userToEdit={userToEdit}
          onClose={handleEditModalClose}
          onShow={showEditModal}
        />
      </div>
      <Table hover className="table-hover">
            <thead>
              <tr>
                <th className="link-primary" onClick={() => handleSort("name")}>
                  Name {`${sortBy === "name" ? sortOrder === "asc" ? "↑" : "↓" : ""}`}
                </th>
                <th className="link-primary"  onClick={() => handleSort("email")}>
                  Email {`${sortBy === "email" ? sortOrder === "asc" ? "↑" : "↓" : ""}`}
                </th>
                <th>Permissions</th>
                <th className="link-primary"  onClick={() => handleSort("organization")}>
                  Organization {`${sortBy === "organization" ? sortOrder === "asc" ? "↑" : "↓" : ""}`}
                </th>
                <th className="link-primary" onClick={() => handleSort("lastPasswordChange")}>
                  Last Login {`${sortBy === "lastPasswordChange" ? sortOrder === "asc" ? "↑" : "↓" : ""}`}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    {user.fname || ''} {user.lname || ''}
                  </td>
                  <td>
                    {user.email} 
                  </td>
                  <td>
                    {user.permissions?.map((permission, index) => (
                      <div key={index}>{permission}</div>
                    ))}
                  </td>
                  <td>
                    {user.org_ids && user.org_ids.length > 0 ? getOrganizationName(user.org_ids[0], organizations) : "N/A"}
                  </td>
                  <td>
                  {formatDate(user.last_login)}
                  </td>
                  <td>
                    <i className="bi bi-pencil fs-6" onClick={() => startEditing(user)}></i>&nbsp;
                    <i className="bi bi-copy fs-6" onClick={() => handleDuplicate(user)}></i>
                  </td>
                </tr>
              ))}
            </tbody>
      </Table>



      
    </div>
  );
};



export default User;
