import { useNavigate } from 'react-router-dom';
import { useState, useEffect, createContext, useRef } from "react"; 
import React, { Fragment } from 'react';
import './projecttemplate.css'; 
import { useToast } from "../../context/ToastProvider";
import ProjectModal from "./projectmodal.component";
import ProjectService from '../../services/project.service';
import ProjectFileService from '../../services/projectfile.service';
import ApiProject from '../../types/project.type'; 
import ApiProjectFile from '../../types/projectfile.type'; 
import { Table, Accordion, Button, Modal, Spinner, Collapse, Dropdown, ButtonGroup } from "react-bootstrap";

const POLLING_FILES_INTERVAL = 6000;

interface ProjectsProps {
  selectedTab: string;
}

const Projects: React.FC<ProjectsProps> = ({ selectedTab }) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ApiProject[]>([]);
  const [projectfiles, setProjectFiles] = useState<ApiProjectFile[]>([]);   
  
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [projectToRename, setProjectToRename] = useState<ApiProject | null>(null);
  const { addToast } = useToast();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [projectfileToDelete, setProjectFileToDelete] = useState<ApiProjectFile | null>(null);
  const [projectToDelete, setProjectToDelete] = useState<ApiProject | null>(null);
  const [showDeleteButton, setShowDeleteButton] = useState<{ [projectId: string]: boolean }>({});


  const [openProjectFileRows, setOpenProjectFileRows] = useState<{ [projectId: string]: { [fileIndex: number]: boolean } }>({});
  const [openProjectAccordion, setOpenProjectAccordion] = useState<{ [projectId: string]: boolean }>({});  // To track open/close state of each accordion
  const [sortOrder, setSortOrder] = useState("Descending");

  const previouslyActiveFiles = useRef(new Set()); // Tracks IDs of files already shown as "Active"


  useEffect(() => {
    if (projects.length > 0 && projectfiles.length > 0) {
      const deleteButtonState: { [projectId: string]: boolean } = {};
      projects.forEach((project) => {
        const filteredFiles = projectfiles.filter((file) => file.project_id === project.id);
        deleteButtonState[project.id] = filteredFiles.length === 0;
      });
      setShowDeleteButton(deleteButtonState);
    }
  }, [projects, projectfiles]); 

  
  // ------------------------------------------------------------------------------
  // Setup project file polling when a file isn't active.
  // ------------------------------------------------------------------------------
  useEffect(() => {
    const pollProjectFileStatus = async () => {
      const projectFilesToPoll = projectfiles.filter((file) => file.status !== "Active");

      if (projectFilesToPoll.length > 0) {
          const updatedFiles = await Promise.allSettled(
          projectFilesToPoll.map((file) => ProjectFileService.getProjectFile(file.id))
          );
          const resolvedFiles : ApiProjectFile[] = updatedFiles
            .filter((result) => result.status === "fulfilled")
            .map((result: any) => result.value);

        if (resolvedFiles.length > 0) {
          
            // Create a lookup map for resolved files to access by ID quickly
          const resolvedFileMap = new Map(resolvedFiles.map((file) => [file.id, file]));

          setProjectFiles((prevFiles) => {
            // Walk through prevFiles and update only ones that changed
            return prevFiles.map((prevFile) => {
              const updatedFile = resolvedFileMap.get(prevFile.id);
              // Merge new file object with the previous file object, otherwise use the old file object
              //NOTE: We don't want to use the new file object entirely, since there's logic in getProjectFiles() that
              //reaches out to 3-4 other APIs and fills in peripheral information. In this case we just get the newly updated file information, 
              // and merge it with the pre-existing aggregated "file" information + the 3-4 other API call response data we got in getProjectFiles() before.
              var willUpdateStatus = !!updatedFile && prevFile.status !== updatedFile.status;
              if (willUpdateStatus) {
                return  { ...prevFile, ...updatedFile }
              } else {
                return prevFile;
              }
            });
          });
        }
      }
    };
  
    const interval = setInterval(pollProjectFileStatus, POLLING_FILES_INTERVAL);
    return () => clearInterval(interval);
  }, [projectfiles]);
  // ------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------
  // Separate useEffect for toast notifications when files go from non-Active to Active
  //NOTE:
  // if we did this in line with the status update, we get a react error when trying
  // to trigger the toast (toast.success) during a React render cycle while you're also updating the state of your component.
  // ------------------------------------------------------------------------------
  useEffect(() => {
    projectfiles.forEach((file) => {
      //When the file switches to Active and wasn't Acive before...
      if (file.status === "Active" && !previouslyActiveFiles.current.has(file.id)) {
        // Trigger toast notification
        addToast("success", "Project File Now Active", `File ${file.filename} is now Active!`); 

        // Add this file ID to the "seen" set
        previouslyActiveFiles.current.add(file.id);
      }
    });
  }, [projectfiles]);
  // ------------------------------------------------------------------------------

  const toggleRow = (projectId: string, index: number) => {
    setOpenProjectFileRows(prevState => ({
      ...prevState,
      [projectId]: {
        ...prevState[projectId],
        [index]: !prevState[projectId]?.[index] // Toggle the state for the specific project and file
      }
    }));
  };

  const toggleAccordion = (projectId: string) => {
    setOpenProjectAccordion(prevState => ({
      ...prevState,
      [projectId]: !prevState[projectId]  // Toggle the open/closed state of the accordion for the specific project
    }));
  };

  const setProjectSortOrder = (sort: string, projects: ApiProject[]) => {
    if (sort === "Descending") {
      const sortedProjects = [...projects].sort((a, b) => {
        if (!a.id || !b.id) return 0;
        return b.id.toString().localeCompare(a.id.toString());
      });
      setProjects(sortedProjects);
    } else if (sort === "Ascending") {
      const sortedProjects = [...projects].sort((a, b) => {
        if (!a.id || !b.id) return 0;
        return a.id.toString().localeCompare(b.id.toString());
      });
      setProjects(sortedProjects);
    }
  }

  const handleSortSelect = (eventKey: any) => {
    if (eventKey === "0") {
      const order = "Descending";
      setSortOrder(order);
      setProjectSortOrder(order, projects);
    } else if (eventKey === "1") {
      const order = "Ascending";
      setSortOrder(order);
      setProjectSortOrder(order, projects);
    }
  };

  useEffect(() => { 
    const fetchData = async () => {
      try {
        await Promise.all([fetchProjects()]);
        await fetchProjectFiles(); 
        
        // Initialize all project accordions as collapsed
        setOpenProjectAccordion(projects.reduce((acc: { [key: string]: boolean }, project) => {
          acc[project.id] = false; // set all project accordions to false (collapsed)
          return acc;
        }, {}));
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [selectedTab]);
  
  useEffect(() => {
    if (projects.length > 0) {
       setOpenProjectAccordion(
         projects.reduce((acc: { [key: string]: boolean }, project) => {
           acc[project.id] = false; // Initialize all project accordions to false (collapsed)
           return acc;
         }, {})
       );
    }
 }, [projects]);

  const fetchProjects = async () => { 
    try { 
      const projects = await ProjectService.getProjects(); 
      setProjects(projects);
      setProjectSortOrder(sortOrder, projects); 

    } catch (error) { 
      console.log(error); 
    } 
  }; 

  const fetchProjectFiles = async () => {
    try {
      const projectfiles = await ProjectFileService.getProjectFiles();  
      setProjectFiles(projectfiles);

      // Seed the previouslyActiveFiles set with active files on initial load
      const activeFileIds = projectfiles
        .filter((file) => file.status === "Active")
        .map((file) => file.id);
      previouslyActiveFiles.current = new Set(activeFileIds);

    } catch (error) {
      console.log(error);
    }
  };
  
  const handleCreateClick = () => {
      setProjectToRename(null);
      setShowProjectModal(true);
  };

  const handleAddProject = (project: ApiProject) => {
    fetchProjects();
  };

  const handleEditModalClose = () => {
    setProjectToRename(null);
    setShowProjectModal(false);
  };

  const handleDownloadClick = async (projectfile: ApiProjectFile) => {
    const projectfile_id = projectfile.id;
    const projectfile_filename = projectfile.filename
    try {
      const url = await ProjectFileService.getProjectFileDownloadURL(projectfile_id)
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", ""); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      addToast("success", "Project File Downloaded", projectfile_filename + " has been downloaded to your computer successfully.");
    } catch (error) {
      console.log(error);
      addToast("error", "Download Error", "An error occurred while attempting to download " + projectfile_filename + ". Please try again.");
    }
  };

  const handleRunProjectTemplate = (project_id: string) => {
    navigate(`/createprojectfile`, { state: { project_id } });
  };

  const handleDeleteConfirm = async () => {
      try {
        setIsDeleting(true);
        if (projectfileToDelete) {
            await ProjectFileService.deleteProjectFile(projectfileToDelete);
            setProjectFiles((prevProjectFiles: ApiProjectFile[]) => 
                prevProjectFiles.filter((projectfile: ApiProjectFile) => projectfile.id !== projectfileToDelete.id)
            );
            addToast("success", "Project File Deleted", `The project file "${projectfileToDelete.filename}" was deleted successfully.`);
        } else 
          if (projectToDelete) {
            await ProjectService.deleteProject(projectToDelete);
            setProjects((prevProject: ApiProject[]) => 
              prevProject.filter((project: ApiProject) => project.id !== projectToDelete?.id)
            );
            addToast("success", "Project Deleted", `The project "${projectToDelete?.project_name}" was deleted successfully.`);
        }
      } catch (error) {
        console.log(error);
        if (projectfileToDelete) {
          addToast("error", "Delete Error", "An error occurred while deleting the project file. Please try again.");
        }
        else if (projectToDelete) {
          addToast("error", "Delete Error", "An error occurred while deleting the project. Please try again.");
        }
      } finally {
        setShowDeleteModal(false);
        setIsDeleting(false);
        setProjectFileToDelete(null);
        setProjectToDelete(null);
      }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case "Uploaded":
        return "status-badge uploaded";
      case "Processing":
        return "status-badge processing";
      case "Active":
        return "status-badge active";
      case "Rejected":
        return "status-badge rejected";
      case "Deleting":
        return "status-badge deleting";
      default:
        return "status-badge default";
    }
  };
  

  const handleDeleteClick = (projectfile: ApiProjectFile) => {
    setProjectFileToDelete(projectfile);
    setShowDeleteModal(true);
  };

  const handleProjectDeleteClick = (project: ApiProject) => {
    setProjectToDelete(project);
    setShowDeleteModal(true);
  }

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setProjectFileToDelete(null);
    setProjectToDelete(null);
  };
  
  return ( 
    <div className="projecttemplate-container"> 
        
        
        <div className="projecttemplate-content"> 
          <div className="projecttemplate-header">
            <h3>Projects</h3>
          </div>  
          <div className="projecttemplate-body"> 
            <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end align-items-center">
              <span><i>Sort:</i>&nbsp;&nbsp;</span>
              <Dropdown as={ButtonGroup} onSelect={handleSortSelect}>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  {sortOrder} ▼
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="0">Descending (Newest to Oldest)</Dropdown.Item>
                  <Dropdown.Item eventKey="1">Ascending (Oldest to Newest)</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
              <Button variant="outline-primary" 
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{e.stopPropagation(); handleCreateClick();}} 
                  className="add-project-button"
                  title="Create a new Project.">
                <i className="bi bi-folder-plus fs-5 ms-0"></i> Project
              </Button>
              <div className="create-project-modal">
                        <ProjectModal
                            projectToEdit={projectToRename}
                            onSaveSuccess={(project) => {
                              handleAddProject(project);
                          }}
                          onClose={handleEditModalClose}
                          onShow={showProjectModal}
                        />
                      </div>
            </div>
            <br></br>
          

            <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Delete Project{projectfileToDelete ? " File" : "" }?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {projectfileToDelete ? (
                  <>
                    Please confirm you want to delete this Project File:<br></br><br></br> <strong><i>{projectfileToDelete?.filename}</i></strong>
                  </>
                ) : (
                  <>
                    Please confirm you want to delete this project:<br></br><br></br> <strong><i>{projectToDelete?.project_name}</i></strong>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteCancel}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteConfirm} disabled={isDeleting} style={{ width: "80px" }}>
                  {isDeleting ? <Spinner animation="border" size="sm" /> : "Confirm"}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>



          <Accordion alwaysOpen>
            {projects.map((project, p_index) => {
              const filteredFiles = projectfiles.filter(file => file.project_id === project.id);
              return (
                <React.Fragment key={project.id}>
                      <Accordion.Item eventKey={p_index.toString()}>
                      <Accordion.Header onClick={() => toggleAccordion(project.id)}>
                      <div className="accordion-header-content">
                        <span><h6>{project.project_name}</h6></span>
                              <div className="add-project-button">
                                {showDeleteButton[project.id] && (
                                  <i
                                  className="bi bi-trash fs-6"
                                  onClick={() => handleProjectDeleteClick(project)}
                                ></i> 
                                )}
                                &nbsp;&nbsp;&nbsp;
                                {/* 
                                NOTE: To put a button on an according header like we are doing here, yields an error in the console:
                                Warning: validateDOMNesting(...): <button> cannot appear as a descendant of <button>. 
                                The solution to this is to use a div, but style it as a button using the btn and btn-outline-* class names
                                */}
                                <div role="button"
                                    onClick={() => handleRunProjectTemplate(project.id)}  
                                    className="btn btn-outline-primary create-project-button ps-0"
                                    title="Create a new Project File.">
                                      <i className="bi bi-file-earmark-plus"></i> Project File
                                </div>
                              </div>
                      </div>
                      </Accordion.Header>
                    <Accordion.Body>
                    <Table hover>
                    <thead>
                      <tr>
                        <th>Template Name</th>
                        <th>File Name</th>
                        <th>Status</th>
                        <th className="text-end">Actions&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredFiles.length > 0 ? (
                        filteredFiles.map((projectfile, index) => (
                          <Fragment key={projectfile.id}>
                            <tr>
                              <td>
                                <Button
                                  variant="link"
                                  onClick={() => toggleRow(project.id, index)} // Pass project ID and file index
                                  aria-controls={`collapse-row-${project.id}-${index}`} // Unique ID based on project and index
                                  aria-expanded={!!openProjectFileRows[project.id]?.[index]} // Check if this row is open
                                >
                                  {!!openProjectFileRows[project.id]?.[index] ? <i className="bi bi-dash-lg"></i> : <i className="bi bi-plus-lg"></i>}
                                </Button>
                                {projectfile.template_name}
                              </td>
                              <td>{projectfile.created_date
                        ? new Date(projectfile.created_date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                          })
                        : 'N/A'}</td>

                                <td>
                                  <span className={getStatusBadgeClass(projectfile.status)}>
                                    {projectfile.status}
                                  </span>

                                  {projectfile.status !== "Active" && (
                                    <Spinner 
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      className="ms-2">
                                      <span className="visually-hidden">Polling...</span>
                                    </Spinner>
                                  )}
                              </td>
                              <td className="text-end">
                                {projectfile.status === "Active" && (
                                  <i className="bi bi-trash fs-6" title="Delete Project File." onClick={() => handleDeleteClick(projectfile)}></i>
                                )}
                                {projectfile.status === "Active" && (
                                  <i className="bi bi-download fs-6" title="Download Project File." onClick={() => handleDownloadClick(projectfile)}></i>
                                )}
                              </td>
                            </tr>
                            
                                <Collapse in={!!openProjectFileRows[project.id]?.[index]}>
                                <tr>
                                  <td colSpan={5} style={{ paddingLeft: '20%' }}>
                                  <div id={`collapse-row-${project.id}-${index}`} className="bg-light" >
                                    <div className="row">
                                      <div className="col-md-2">
                                        <i>File Name:</i>
                                      </div>
                                      <div className="col-md-9">
                                        {projectfile.filename || "Processing..."}
                                      </div>
                                    </div>
                                  </div>
                                  </td>
                                </tr>
                                </Collapse>
                              
                          </Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4}>No Project Files Active</td>
                        </tr>
                      )}
                    </tbody>

                  </Table>
                  </Accordion.Body>
                  </Accordion.Item>
                  <br></br>
                  
                </React.Fragment>
              );
            })}
            </Accordion>

        </div> 
      
      
    </div> 
  ); 
}; 
 
export default Projects;