// OrganizationService.js
import axios from 'axios';
import { API_BASE_URL } from '../constants';
import { ApiOrganization } from '../types/organization.type';
import authHeader from "./auth-header"; 


class OrganizationService {
    private baseUrlAdmin = API_BASE_URL + 'admin/organization';
    private baseUrl = API_BASE_URL + 'organization';

    async getOrganization(org_id: string): Promise<ApiOrganization> { //use an id from org instead of whole org obj
      const response = await axios.get(`${this.baseUrl}/${org_id}`,{ headers: authHeader() });
      return response.data;
    }
    async getSystemPrompt(org_id: string): Promise<string> { //use an id from org instead of whole org obj
      const response = await axios.get(`${this.baseUrl}/${org_id}/systemprompt`,{ headers: authHeader(), });
      return response.data;
    }
    async setSystemPrompt(org_id: string, systemPrompt: string): Promise<string> { //use an id from org instead of whole org obj
      const response = await axios.put(`${this.baseUrl}/${org_id}/systemprompt`,
        { systemPrompt },
        { headers: authHeader(), });
      return response.data;
    }
    async getOrganizations(): Promise<ApiOrganization[]> {
      const response = await axios.get(this.baseUrlAdmin, { headers: authHeader() });
      return response.data;
    }
    async createOrganization(org: ApiOrganization): Promise<ApiOrganization> {
      const response = await axios.post(this.baseUrlAdmin, org, { headers: authHeader() });
      return response.data;
    }
  
    async updateOrganization(org: ApiOrganization): Promise<ApiOrganization> {
      const response = await axios.put(`${this.baseUrlAdmin}/${org.id}`, org, { headers: authHeader() });
      return response.data;
    }

    async getAllTools() {
      const response = await axios.get(`${API_BASE_URL}admin/tools`, { headers: authHeader() });
      return response.data;
    }

    async getOrgTools(organization: ApiOrganization) {
      const response = await axios.get(`${API_BASE_URL}tools/${organization.id}`, { headers: authHeader() });
      return response.data.tools
    }
  }
  
  export default new OrganizationService();